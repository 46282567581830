.rpb-cards {
  .card {
    border:0;
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
    .card-header {
      background:unset;
      border-bottom:unset;
    }
  }
  // Vertical Align Class
  .vertically-aligned-card {
    display:flex;
    flex-direction:column;
    justify-content:center;
  }
  // Image Classes
  .bg-img-card {
    display:none;
  }
  // Default Slider Styles
  &.bootstrap-cards-slider {
    .splide__slider {
      .rpb-slider-arrows {
        .splide__arrow--prev {
          left:0;
        }
        .splide__arrow--next {
          right:0;
        }
      }
      .splide__track {
        margin-left:3rem;
        margin-right:3rem;
      }
      .splide__pagination {
        bottom:-2.5rem;
      }
    }
  }
  .pulled-icon {
    display: inline-flex;
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    margin-bottom:20px;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: -40px;
      right: 15px;
      margin-bottom:0;
    }
  }
  .pulled-icon-center {
    display: inline-flex;
    width: 70px;
    height: 70px;
    align-items: center;
    justify-content: center;
    margin-bottom:20px;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: -35px;
      display:flex;
      margin-left:auto;
      margin-right:auto;
      left:0;
      right:0;
      margin-bottom:0;
    }
  }
  .card-hover-shadow {
    transition:all 350ms ease-in-out;
    &:hover {
      cursor:pointer;
      @extend .shadow-lg;
    }
  }
}
