$brand-primary: #1157ed;
$brand-primary-offset: #1157ed;
$brand-secondary: #67caec;
$accent: #11f5eb;
$light: #f2f2f2;
$dark: #222222;
$error: #d81e00;
$gray: #888;
$white: #fff;
$body-font: #222;
$gray: #888;
$medium: #dee2e6;
$inactive: #8c8c8c;
