.hero-animated-star {
  display:none;
  width: 100%;
  position: absolute;
  top: -50px;
  padding-left: inherit;
  left: 0;
  padding-right: inherit;
  right: 0;
  @include media-breakpoint-up(lg) {
    display:block;
  }
  svg {
    border-radius:50%;
    @extend .border;
    width:100px;
    @extend .background-black-frost;
    padding:5px;
  }
}

.background-white-semi {
  background-color: rgba(255,255,255, 0.7);
}

.hero-wave-container {
  @include media-breakpoint-up(lg) {
    margin-bottom:-50px;
  }
}

.hero-wave {
  @include media-breakpoint-down(lg) {
    display:none;
  }
  height: 150px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right:0;
  z-index:2;
}

.ph-style-2 {
  .ph-title {
    box-shadow:6px 6px 0 $brand-secondary;
    text-shadow: 2px 2px #4f4f4f;
  }
}
